/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import { ContactFormSpanish } from '@beelineloans/cx-library/src/components/forms/ContactFormSpanish';

const TemplateWrap = styled.div`
  @media only screen and (${Theme.mediaBreakpoints.tablet}) {
    #header-child-left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;

const IndexPage = () => {
  let templateProps;
  let headerProps;

  return (
    <TemplateWrap>
      <Template
        sideNavLinksComponent={false}
        headline={ContactFormSpanish}
        $realtorFirmName={null}
        headerComponent={ContactFormSpanish}
        {...templateProps}
        headerProps={{
          options: { headerRatings: false, voomly: false },
          ...headerProps
        }}
      >
        <SEO
          path={CONST.LINKS.MAIN.SPANISH_CONTACT.DEFAULT}
          title="Let’s connect you with a Beeline Loan Guide."
          description="Drop your details in here and a licensed Loan Guide will reach out."
          antiFlicker
        />
      </Template>
    </TemplateWrap>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object,
  referral: PropTypes.bool
};

IndexPage.defaultProps = {
  referral: false
};

export default IndexPage;
